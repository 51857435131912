import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/index.vue'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'login',
  component: login
}, {
  path: "/Home",
  redirect: "/Home/homepage",
  component: () => import("@/views/Home.vue"),
  meta: {
    title: "首页",
  },
  children: [{
    path: "homepage",
    component: () => import("@/views/homepage/index.vue"),
    meta: {
      title: "客户管理",
    }
  }, {
    path: "agent",
    component: () => import("@/views/agent/index.vue"),
    meta: {
      title: "代理管理",
    }
  }, {
    path: "agent/customerDetails",
    component: () => import("@/views/agent/customerDetails.vue"),
    meta: {
      title: "客户详情",
    },
  }, {
    path: "account",
    component: () => import("@/views/account/index.vue"),
    meta: {
      title: "账户详情",
    }
  }]
}]

const router = new VueRouter({
  routes
})

export default router