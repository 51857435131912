<template>
  <div class="login-view">
    <div class="login-box">
      <div class="title">
        <div class="t1">晟工数智数字人管理后台</div>
        <div class="t2">欢迎登录</div>
      </div>
      <div class="pwdRegister_content login-content">
        <el-form
          :model="ruleForm"
          ref="pwdRegister"
          status-icon
          :rules="pwdRegRules"
        >
          <el-form-item status-icon prop="telNum">
            <el-input
              prefix-icon="el-icon-user"
              placeholder="请输入手机号"
              maxlength="11"
              v-model="ruleForm.telNum"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="loginType" prop="pwd">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入验证码"
              type="password"
              maxlength="6"
              v-model="ruleForm.pwd"
              @keyup.enter.native="throttleLogin"
              style="width: 60%"
            ></el-input>
            <Code class="getTelVerify" :phone="ruleForm.telNum"></Code>
          </el-form-item>
          <el-form-item v-else status-icon prop="password">
            <el-input
              prefix-icon="el-icon-lock"
              placeholder="请输入密码"
              v-model="ruleForm.password"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item size="large">
            <div
              class="btn"
              @click="throttleLogin"
              :disabled="loading"
              id="confirmBtn"
              v-loading.fullscreen.lock="loading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(0, 0, 0, 0.8)"
              element-loading-text="登录中..."
            >
              登录
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="loginChange flex">
        <div @click="loginChange">
          {{ loginType ? "密码登录" : "验证码登录" }}
        </div>
        <div @click="modify.show = true" v-if="!loginType">忘记密码</div>
      </div>
    </div>
    <el-dialog
      class="commonDialog"
      title="重置密码"
      :visible.sync="modify.show"
      width="500px"
      @close="modifyDown"
    >
      <el-form
        :model="modify.ruleForm"
        :rules="modify.rule"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="手机号" prop="user">
          <el-input
            class="waicengPhone"
            v-model="modify.ruleForm.user"
            placeholder="请输入手机号"
            clearable
            style="width: 100% !important"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="oncePassword">
          <el-input
            class="waicengPhone"
            placeholder="请输入验证码"
            type="password"
            maxlength="6"
            v-model="modify.ruleForm.oncePassword"
            style="width: 70% !important"
          ></el-input>
          <Code class="modifyCode" :phone="modify.ruleForm.user"></Code>
        </el-form-item>
        <template v-if="modify.ruleForm.oncePassword">
          <el-form-item label="新密码" prop="password">
            <el-input
              class="waicengPhone"
              v-model="modify.ruleForm.password"
              placeholder="请输入新密码"
              clearable
              show-password
              style="width: 100% !important"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认新密码" prop="onPassword">
            <el-input
              class="waicengPhone"
              v-model="modify.ruleForm.onPassword"
              placeholder="请输入确认密码"
              show-password
              clearable
              style="width: 100% !important"
            ></el-input>
          </el-form-item>
        </template>
        <div
          class="flex"
          style="margin-top: 50px; justify-content: center"
        >
          <el-button type="primary" plain @click="modify.show = false"
            >取消</el-button
          >
          <el-button type="primary" @click="resetForm">保存</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      ruleForm: {
        telNum: "",
        pwd: "",
      },
      pwdRegRules: {
        telNum: [
          {
            required: true,
            trigger: "blur",
            message: "请输入账号/手机号",
          },
        ],
        pwd: [
          {
            required: true,
            trigger: "blur",
            message: "请输入验证码",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "请输入密码",
          },
        ],
      },
      loginType: true,
      modify: {
        show: false,
        ruleForm: {},
        rule: {
          user: [
            {
              required: true,
              trigger: "blur",
              message: "请输入账号/手机号",
            },
          ],
          oncePassword: [
            {
              required: true,
              trigger: "blur",
              message: "请输入验证码",
            },
          ],
          password: [
            {
              required: true,
              trigger: "blur",
              message: "请输入密码",
            },
          ],
          onPassword: [
            {
              required: true,
              trigger: "blur",
              message: "请输入新密码",
            },
          ],
        },
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    throttleLogin() {
      this.$refs["pwdRegister"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let cs = this.ruleForm;
          let params;
          let url;
          if (this.loginType) {
            params = {
              phone: cs.telNum,
              oncePassword: cs.pwd,
            };
            url = "/login/loginCode";
          } else {
            params = {
              user: cs.telNum,
              password: cs.password,
            };
            url = "/login/loginPassword";
          }
          this.http
            .post(url, params)
            .then((res) => {
              if (res.data.success) {
                this.setCacheData("userInfo", JSON.stringify(res.data.data));
                this.getToken();
              } else {
                this.$message.error(res.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    //切換登錄方式
    loginChange() {
      this.loginType = !this.loginType;
      this.$refs.pwdRegister.clearValidate();
    },
    //获取token
    getToken() {
      let params = {};
      this.http
        .post("/open/company/gwskip/token", params)
        .then((res) => {
          if (res.data.code == 200) {
            this.$message.success("登录成功！");
            this.setCacheData("Authorization", res.data.data);
            setTimeout(() => {
              const userInfo = JSON.parse(this.getCacheData("userInfo"));
              if (userInfo.type == "AGENT") {
                this.$router.push("/Home/account");
              } else {
                this.$router.push("/Home");
              }
            }, 500);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    },
    //关闭修改密码弹窗
    modifyDown() {
      this.$refs.ruleForm.resetFields();
      this.modify.show = false;
    },
    //重置密码
    resetForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let obj = this.modify.ruleForm;
          if (obj.password !== obj.onPassword) {
            this.$message("两次密码不一致,请重新确认密码");
            obj.onPassword = null;
            return;
          }
          let params = {
            user: obj.user,
            password: obj.password,
            oncePassword: obj.oncePassword,
          };
          this.http
            .post("/login/resetPassByCode", params)
            .then((res) => {
              if (res.data.success) {
                this.$message.success("重置成功！");
                this.modifyDown();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {});
        }
      });
    },
  },
  components: {},
};
</script>

<style lang="less" scoped>
.modifyCode {
  float: right;
  font-size: 16px;
  color: #00bcd4;
}
.loginChange {
  font-size: 14px;
  color: white;
  margin-top: 20px;
  cursor: pointer;
}
.getTelVerify {
  cursor: pointer;
  box-sizing: border-box;
  float: right;
  width: 150px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #00bcd4;
  font-size: 16px;
  color: #00bcd4;
}

.pwdRegister_content {
  .el-input {
    font-size: 16px;
  }
  /deep/.el-input__inner {
    height: 60px !important;
    font-size: 16px;
  }
  margin-top: 50px;
  #confirmBtn {
    background: #006eff;
  }

  .btn {
    cursor: pointer;
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background: #d8d8d8;
    color: #ffffff;
    text-align: center;
    line-height: 60px;
    font-size: 18px;
  }

  .otherRegister {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    font-size: 16px;
    .toVerify {
      color: #006eff;
      float: left;
      cursor: pointer;
    }

    .toForget {
      cursor: pointer;
      color: #666666;
      float: right;
    }
  }
}
.login-view {
  .login-box {
    .title {
      .t2 {
        font-size: 28px;
      }
      .t1 {
        font-size: 38px;
        margin-bottom: 30px;
      }
      font-weight: bold;
      color: white;
      text-align: center;
    }
    width: 423px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 80px 60px;
  }
  background: url("../../images/back/back.png") no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}
</style>
