import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import store from './store'
import MetaInfo from 'vue-meta-info'
import less from 'less'
import http from './api/http.js'
import qs from 'qs'
import router from './router'
import axios from 'axios'
import "./utils/int";
import VueAnimateNumber from 'vue-animate-number'
import moment from "moment";
import Code from './components/Code.vue'
const VueScrollTo = require('vue-scrollto')
Vue.component("Code", Code);
Vue.use(VueAnimateNumber)
Vue.use(less)
Vue.use(MetaInfo)
Vue.use(VueScrollTo)
Vue.config.productionTip = false
Vue.prototype.axios = axios;
Vue.prototype.http = http;
Vue.prototype.qs = qs;
Vue.use(VueScrollTo, {
	container: 'body', // 滚动元素
	duration: 500, // 动画时长
	easing: 'ease', // 动画曲线
	offset: 0, // 滚动终点距离父元素顶部距离
	force: true, // 强制立即执行，即便元素是可见的
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false, // x 轴禁止滚动
	y: true
})
const mixin = {
	filters: {
		keepOne(val) {
			return Number(val.toString().match(/^\d+(?:\.\d{0,1})?/));
		},
		handlePay(aa, bb) {
			var textSalary = ''
			if ((aa == '' && bb == '') || (aa == 0 && bb == 0)) {
				textSalary = '面议'
			} else if (aa != '' && bb == '') {
				textSalary = aa + '以上'
			} else if (aa == '' && bb != '') {
				textSalary = bb + '以下'
			} else if (aa != '' && bb != '') {
				textSalary = aa + '-' + bb
			}
			return textSalary
		}
	},
	methods: {
		/**
		 * 返回顶部
		 */
		topFunction() {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
		},
		Dateformat(cellValue, type) {
			//日期格式化
			return cellValue ? moment(cellValue).format(type) : '--';
		},
		filterReturn(arr, type) {
			//枚举过滤
			return type ? arr.filter((item) => {
				return item.value == type;
			})[0].label : '--';
		},
		/**
		 * @description:
		 * @param {*} res 导出接口直接返回的res
		 * @return {*}
		 */
		toExport(res) {
			let that = this;
			let data = res.data;
			let fileReader = new FileReader();
			fileReader.onload = function () {
				try {
					let jsonData = JSON.parse(this.result);
					that.$message({
						message: jsonData.errorMsg,
						type: "error",
					});
				} catch (e) {
					//对后端返回文件名转码,获取到正常文件名
					var name = window.escape(res.headers["content-disposition"]);
					let fname = window.decodeURI(name).split("%3D")[1];
					let filename = name ? window.decodeURI(fname) : "文件.xlsx";
					let downloadDelegate = document.createElement("a");
					downloadDelegate.setAttribute("href", URL.createObjectURL(res.data));
					downloadDelegate.setAttribute("download", filename);
					downloadDelegate.click();
					downloadDelegate = null;
					that.$message({
						message: "导出信息成功",
						type: "success",
					});
				}
			};
			fileReader.readAsText(data);
		},
		getMultipleTable(list) {
			//批量获取枚举（建议使用这个，减少接口调用次数）
			return new Promise((resolve, reject) => {
				this.http
					.post("/getTableDataByListParam", list)
					.then((res) => {
						resolve(res.data.data);
					})
					.catch();
			});
		},
		//返回请求头数据
		returnUrl() {
			return new Promise((resolve) => {
				this.http.returnUrl().then((res) => {
					resolve(res);
				});
			});
		},
		handelHttps(str) {
			if (!str) {
				return ''
			}
			if (str.startsWith("https://")) {
				return str
			} else if (str.startsWith("http://")) {
				return str.replace("http://", "https://")
			} else {
				return 'https://' + str
			}
		},
		goBack() {
			this.$router.go(-1);
		},
		//秒转分处理
		minute(value) {
			return (+value / 60).toFixed(1);
		},
		setCacheData(name, val) {
			// 设置缓存
			localStorage.setItem(name, val);
		},
		getCacheData(name) {
			// 获取缓存
			return localStorage.getItem(name);
		},
		removeCacheData(name) {
			// 清除缓存
			localStorage.removeItem(name);
		},
	}
}
Vue.mixin(mixin)

new Vue({
	store,
	router,
	render: h => h(App),
}).$mount('#app')