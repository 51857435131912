<!-- 模块说明 -->
<template>
  <div class="container">
    <div @click="getTelVerify">{{ btnTxt }}</div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "Code",
  props: {
    phone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      btnTxt: "获取验证码",
      time: 60,
      disabled: false,
    };
  },
  watch: {},
  created() {},
  methods: {
    getTelVerify() {
      if (!this.phone) {
        this.$message("请输入手机号");
        return false;
      }
      if (this.disabled) return;
      let params = {
        target: this.phone,
      };
      this.http
        .get("/login/getCode", params)
        .then(() => {
          this.time = 60;
          this.timer();
        })
    },
    //发送手机验证码倒计时
    timer() {
      if (this.time > 0) {
        this.disabled = true;
        this.time--;
        this.btnTxt = this.time + "s";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnTxt = "发送验证码";
        this.disabled = false;
      }
    },
  },
  computed: {},
};
</script>
<style lang="less" scoped>
</style>
