<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// import broadside from "./components/broadside.vue";
export default {
  name: "app",
  components: {
    // broadside,
  },
  mounted() {
    // process.on('unhandledRejection', error => {
    // 	console.error('unhandledRejection', error);
    // 	process.exit(1) // To exit with a 'failure' code
    // });
  },
};
</script>

<style lang="less">
.el-pagination {
  float: right;
  font-weight: 500 !important;
  margin: 30px auto 0;
}

.el-pager .active {
  background-color: #66b1ff !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FadeInTwo {
  animation: fadeInUpTwo 1000ms both;
}
.FadeNone {
  opacity: 0;
}

/* 由下而上淡入 */
.FadeIn {
  animation: fadeInUp 1000ms both;
}

/* 从左侧淡入 */
.fadeInLeft {
  animation: fadeInLeft 1000ms both;
}

.slideIn {
  animation: slideInRight 1000ms both;
}

/* 由底部旋转淡入 */
.rotateIn {
  animation: rotateInLeft 1000ms both;
}

/* 放大10倍淡入 */
.puffBigIn {
  animation: puffBigIn 1500ms both;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes puffBigIn {
  0% {
    transform: scale3d(10, 10, 10);
    opacity: 0;
  }

  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInLeft {
  from {
    transform-origin: center 80%;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    transform-origin: center 80%;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes floatY {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(30%, 0, 0);
    visibility: visible;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpTwo {
  from {
    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes jump {
  from,
  10% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  30% {
    transform: translateY(10%) scale3d(1.05, 0.8, 1);
  }

  50% {
    transform: translateY(-50%) scale3d(1, 1.1, 1);
  }

  65% {
    transform: translateY(0) scale3d(1, 1, 1);
  }

  75% {
    transform: translateY(8%) scale3d(1.08, 0.84, 1);
  }

  85% {
    transform: translateY(6%) scale3d(1.05, 0.88, 1);
  }

  90%,
  to {
    transform: none;
  }
}
.el-select .el-input.is-focus .el-input__inner {
  border-color: #66b1ff !important;
}

.el-select-dropdown .selected {
  color: #66b1ff !important;
}

body,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p,
th,
td,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
select {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  -webkit-text-size-adjust: 100%;
}

a {
  color: #2d374b;
  text-decoration: none;
}

a:hover {
  color: #66b1ff;
  text-decoration: underline;
}

em {
  font-style: normal;
}

li {
  list-style: none;
}

img {
  border: 0;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

p {
  word-wrap: break-word;
}
/* // 设置滚动条整体样式 */
::-webkit-scrollbar {
  width: 10px;
}

/* / 滚动条方块样式 */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  cursor: pointer;
}

/* // 滚动条轨道样式 */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: white;
}
/*commonDialog弹窗样式*/
.commonDialog {
  .el-input,
  .el-select,
  .el-cascader {
    width: 100%;
    // height: 34px !important;

    .el-input__inner {
      // height: 34px !important;
    }

    .el-input__suffix,
    .el-input__prefix {
      .el-input__icon {
        // line-height: 34px !important;
      }
    }
  }
}

.commonDialog {
  .el-dialog__body {
    padding: 20px !important;
  }
  .el-form-item__label {
    color: white !important;
  }
  .el-dialog {
    position: absolute;
    margin-top: 0 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .el-dialog__header {
      position: relative;
      padding: 20px !important;
      background-color: #090535;
      //border-bottom: 2px solid #C8F2FF;
      .el-dialog__title {
        font-size: 20px;
        color: white;
      }

      .el-dialog__headerbtn {
        top: 18px;
        font-size: 24px;

        .el-dialog__close {
          color: white;
        }
        .el-dialog__close:before {
          font-weight: 400;
        }
      }
    }

    .el-dialog__header:before {
      position: absolute;
      width: calc(100% - 40px);
      height: 1px;
      background: #1a1f58;
      content: "";
      left: 20px;
      top: 58px;
    }

    .el-dialog__body {
      background-color: #090535;
      margin-top: -1px;
      .el-select {
        width: 100%;
      }
    }
    .el-dialog__footer {
      padding: 20px;
      text-align: center;
      background: #090535;
      border-top: 1px solid #1a1f58 !important;
    }
  }
}
//表格样式
.HNMR {
  &.noHead {
    &:before {
      height: 0;
    }
    thead {
      display: none;
    }
  }

  thead {
    th {
      height: 56px;
      padding: 0;
      background: #101e69 !important;
      font-weight: 400;
      font-size: 14px;
      font-family: PingFang SC;
      color: #ffffff;
      border-bottom: none;
    }
  }
  .nodata {
    img {
      width: 100px;
      height: 100px;
      margin: 20px auto 0;
    }
    text-align: center;
    margin-bottom: 20px;
  }
  margin-top: 30px;
}
.el-table tr {
  background-color: #090535 !important;
  color: white !important;
}
.el-table td.el-table__cell {
  border-bottom: none !important;
}
.el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #110c49 !important;
  color: white !important;
}
.el-table th.el-table__cell.is-leaf {
  border-bottom: none !important;
}
.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: rgba(0, 0, 0, 0) !important;
}
.el-table__body tr.hover-row > td.el-table__cell {
  background: #182364 !important;
}
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background: #182364 !important;
}
.el-table__empty-block {
  background: #120d4a;
}
//输入框样式
.waicengPhone {
  width: 228px !important;
  flex-shrink: 0;
  margin-right: 20px;
  background-color: #030b34 !important;
  border: 1px solid #374597 !important;
  border-radius: 4px;
  .el-range-separator {
    color: #979797 !important;
  }
  .el-input__inner,
  .el-range-input {
    background-color: #030b34;
    border: none;
    color: white !important;
  }
}
.is-disabled .el-input__inner {
  background-color: #030b34 !important;
  border: none;
  color: white !important;
}
//按钮样式
.el-button--primary {
  background: #2020d3 !important;
  border: none !important;
}
.el-button--primary.is-plain {
  color: white !important;
  background: #030b34 !important;
  border: 1px solid #374597 !important;
}
//分页样式
.el-pagination {
  .el-input__inner,
  .el-range-input {
    background-color: #030b34;
    border: 1px solid #374597 !important;
    color: white !important;
  }
  .el-pagination__total,
  .el-pagination__jump {
    color: white;
  }
  .btn-prev,
  .btn-next {
    background-color: #030b34 !important;
    border: 1px solid #374597;
    color: white !important;
  }
  .el-pager .active {
    background-color: #121276 !important;
  }
  .el-pager li {
    background-color: #030b34 !important;
    border: 1px solid #374597;
    color: white !important;
    border-left: 1px solid #374597 !important;
  }
}
</style>
